.main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.menu {
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
}

.menu>.fill {
  flex: 1;
}

.scene {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.scene canvas {
  outline: none;
  width: 100%;
  height: 100%;
}